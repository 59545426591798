import * as React from "react";
import moment from "moment";
import { usePackageList, getPackage, useToken, useApi } from "../../api";
import {
  DownloadOutlined,
  CodeSandboxOutlined,
  ExperimentOutlined,
  LoadingOutlined,
  FileZipOutlined,
  CopyOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Card, Layout, Space, Typography, Divider, Table, Row, Col, Button, Tooltip } from "antd";
import { FunctionComponent, useState, useEffect, useContext } from "react";
import { ParsedUser, SymbiontPackage, SymbiontPackageTypes } from "../../../../shared/types";
import { SortOrder } from "antd/es/table/interface";
import "../../index.less";
import styles from "./styles.module.css";
import amplitude from "amplitude-js";
import { Link, useParams } from "react-router-dom";
const { Content, Sider } = Layout;
const { Title, Text } = Typography;
import { Amplitude } from "@amplitude/react-amplitude";

function formatBytes(a, b = 2) {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
}

type CollectedPackages = Record<SymbiontPackageTypes, Record<string, SymbiontPackage>>;

type State = {
  packages: CollectedPackages;
  loading: boolean;
  loadingPackages: Record<string, boolean>;
  error: Error;
};

type Props = {
  user: ParsedUser;
};

type ButtonProps = {
  release: SymbiontPackage;
  text: string;
};

type LatestPackage = {
  mac: SymbiontPackage;
  linux: SymbiontPackage;
};

export const DownloadButton: React.FC<ButtonProps> = ({ release, text }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const token = useToken();

  const handleClick = async (event) => {
    if (!loading) {
      setLoading(true);
      event.preventDefault();
      console.log("get Package");
      amplitude.getInstance().logEvent("PORTAL_USER_DOWNLOAD", { release, text });
      getPackage(release, token).then((res) => {
        let a = document.createElement("a");
        a.href = res;
        a.download = release.name;
        a.click();
        a.remove();
        setLoading(false);
      });
    }
  };

  return (
    <Tooltip
      style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
      title={
        <>
          <Text style={{ color: "white" }}>
            <FileZipOutlined /> {release.name} ({formatBytes(release.metadata.size)})
          </Text>
          <br />
          <Text style={{ fontSize: "10px", color: "#ccc" }}>{`SHA256: ${release.metadata.sha256Hash}`}</Text>
        </>
      }
    >
      {/* <Tooltip title={`${release.name} (${formatBytes(release.metadata.size)})`}> */}
      <Button
        onClick={handleClick}
        type="primary"
        shape="round"
        icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
        size="small"
      >
        {text}
      </Button>
    </Tooltip>
  );
};

export const Main: React.FC = () => {
  const [loading, data, error] = usePackageList();
  const [latestSDK, setLatestSDK] = useState<LatestPackage>();
  const [latestRelease, setLatestRelease] = useState<LatestPackage>();
  const [tableData, setTableData] = useState([]);
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [curlCopied, setCurlCopied] = useState<boolean>(false);
  const params = useParams();
  console.log(params);

  const platformNames = { linux: "Linux / WSL", mac: "macOS" };
  const releaseTypeNames = { assembly: "Release", "assembly-sdk": "SDK" };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      filters: [
        {
          text: "Releases",
          value: "release",
        },
        {
          text: "develop",
          value: "develop",
        },
      ],
      render: (version, record) => {
        let out = version;
        if (record.preRelease !== "") {
          out = `${out}.${record.preRelease}`;
        }
        return out;
      },
      sorter: (a, b) => a.version.localeCompare(b.version),
      onFilter: (value, record) => {
        if (value === "release") {
          return !(record.preRelease || record.version.includes("develop"));
        } else if (value === "develop") {
          return record.version.includes("develop");
        } else {
          return true;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "SDK",
          value: "assembly-sdk",
        },
        {
          text: "Release",
          value: "assembly",
        },
      ],
      render: (type) => releaseTypeNames[type],
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      filters: [
        {
          text: "macOS",
          value: "mac",
        },
        {
          text: "Linux / WSL",
          value: "linux",
        },
      ],
      render: (platform) => platformNames[platform],
      sorter: (a, b) => a.platform.localeCompare(b.platform),
      onFilter: (value, record) => record.platform.indexOf(value) === 0,
    },
    {
      title: "Release Date",
      dataIndex: ["metadata", "timeCreated"],
      key: "timeCreated",
      render: (time: Date) => {
        return moment(time).format("MMM Do YYYY");
      },
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a, b) => new Date(a.metadata.timeCreated).getTime() - new Date(b.metadata.timeCreated).getTime(),
    },
    {
      title: "Size",
      dataIndex: ["metadata", "size"],
      key: "size",
      render: (size) => {
        return formatBytes(size);
      },
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <DownloadButton text="" release={record} />,
    },
  ];

  useEffect(() => {
    if (!loading) {
      setTableData([...(data.packages.assembly || []), ...(data.packages["assembly-sdk"] || [])]);
      if (data.packages["assembly-sdk"] && data.packages["assembly-sdk"].length > 0) {
        const latestMacSDK = data.packages["assembly-sdk"]
          .filter((p) => p.platform === "mac")
          .sort((a, b) => {
            return new Date(b.metadata.timeCreated).getTime() - new Date(a.metadata.timeCreated).getTime();
          })[0];
        const latestLinuxSDK = data.packages["assembly-sdk"]
          .filter((p) => p.platform === "linux")
          .sort((a, b) => {
            return new Date(b.metadata.timeCreated).getTime() - new Date(a.metadata.timeCreated).getTime();
          })[0];
        setLatestSDK({ mac: latestMacSDK, linux: latestLinuxSDK });
      }
      if (data.packages["assembly"] && data.packages["assembly"].length > 0) {
        const latestMacRelease = data.packages["assembly"]
          .filter((p) => p.platform === "mac")
          .sort((a, b) => {
            console.log("mac release", a, b);
            return new Date(b.metadata.timeCreated).getTime() - new Date(a.metadata.timeCreated).getTime();
          })[0];
        const latestLinuxRelease = data.packages["assembly"]
          .filter((p) => p.platform === "linux")
          .sort((a, b) => {
            console.log("linux release", a, b);
            return new Date(b.metadata.timeCreated).getTime() - new Date(a.metadata.timeCreated).getTime();
          })[0];
        setLatestRelease({ mac: latestMacRelease, linux: latestLinuxRelease });
      }
      setDataReady(true);
    }
  }, [loading, data]);

  useEffect(() => {
    if (!!error) {
      console.log(`Error fetching packages: ${error}`);
    }
  }, [error]);

  return (
    <Amplitude>
      {({ logEvent }) => (
        <Layout className="site-layout-background" style={{ padding: "24px 0" }}>
          <Content style={{ padding: "0 24px", minHeight: 280 }}>
            <div className={styles.content}>
              <Title>Latest Releases</Title>
              {!!error && <p style={{ color: "red" }}>Unable to load packages. Please refresh the page.</p>}
              <Row gutter={16}>
                <Col span={12} style={{ display: "inline-flex" }}>
                  <Card
                    style={{ minWidth: "300px" }}
                    title={
                      <>
                        <CodeSandboxOutlined /> Latest Release
                      </>
                    }
                    actions={
                      dataReady && latestRelease
                        ? [
                            <DownloadButton text="macOS" release={latestRelease.mac} />,
                            <DownloadButton text="Linux / WSL" release={latestRelease.linux} />,
                          ]
                        : loading
                        ? [
                            <div>
                              <LoadingOutlined /> Loading latest releases...
                            </div>,
                          ]
                        : [<div>Assembly releases unavailable</div>]
                    }
                  >
                    <Text>
                      The Assembly release includes tooling for node deployment, node administration, and
                      network orchestration. <br />
                      Assembly is a decentralized database <i>and</i> application platform - a computer network
                      with no central authority, allowing multiple independent parties to share data and
                      application logic in real time.
                    </Text>
                  </Card>
                </Col>
                <Col span={12} style={{ display: "inline-flex" }}>
                  <Card
                    style={{ minWidth: "300px" }}
                    title={
                      <>
                        <ExperimentOutlined /> SDK
                      </>
                    }
                    loading={false}
                    actions={[
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          padding: "10px",
                          margin: "0 25px",
                          background: "#F0F2F5",
                        }}
                      >
                        <code
                          style={{ fontFamily: "monospace", cursor: "hand" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              "curl -L https://raw.githubusercontent.com/symbiont-io/symenv/main/install.sh | bash"
                            );
                            setCurlCopied(!curlCopied);
                          }}
                          children={[
                            "curl -L https://raw.githubusercontent.com/symbiont-io/symenv/main/install.sh | bash",
                          ]}
                        />
                        {curlCopied ? (
                          <CheckCircleOutlined
                            style={{
                              color: "green",
                            }}
                          />
                        ) : (
                          <CopyOutlined />
                        )}
                      </div>,
                    ]}
                  >
                    <Text>
                      Our new SDK manager will help you author contracts and develop on Assembly like never
                      before. Manage multiple SDK versions side-by-side and experience the most modern tooling
                      while working securely. To get started, run the command below in your shell and continue
                      with our &nbsp;
                      <Link target="_blank" to="/new_docs/" onClick={() => logEvent("PORTAL_NAVIGATE_DOCS")}>
                        Docs
                      </Link>
                      .
                    </Text>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Title>All Releases</Title>
              <Table
                dataSource={tableData}
                columns={columns}
                rowKey={(el) => `${el.name}-${el.version}-${el.metadata.sha256Hash}`}
                loading={loading}
              />
            </div>
          </Content>
        </Layout>
      )}
    </Amplitude>
  );
};
