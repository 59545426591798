import * as React from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { apiGet } from "../../api";

export const Login = ({ children, appState }) => {
  const params = new URLSearchParams(useLocation().search);
  const path = params.get("path");

  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [isAuthenticatedWithBackend, setIsAuthenticatedWithBackend] = useState<boolean>(false);
  const [pollingBackend, setPollingBackend] = useState<boolean>(false);

  // After authenticating with Auth0, we have to authenticate with the backend API
  // Otherwise, redirects such as /docs will break. This just polls until we've
  // retrieved an Auth0 token and the backend API has accepted it.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const fetchUser = (attempt: number) => {
      if (!isAuthenticatedWithBackend) {
        getAccessTokenSilently().then((token) => {
          apiGet("/me", token).then(
            () => {
              console.log("Login | Authed to API, let's go");
              setPollingBackend(false);
              setIsAuthenticatedWithBackend(true);
            },
            () => {
              setIsAuthenticatedWithBackend(false);
              const backoff = Math.min(attempt * 200, 2000);
              console.log(`Login | Error on /me, retrying in ${backoff}`);
              timeout = setTimeout(() => fetchUser(attempt + 1), backoff);
            }
          );
        });
      }
    };
    if (isAuthenticated && !isAuthenticatedWithBackend) {
      fetchUser(1);
    }
    return () => clearInterval(timeout);
  }, [isAuthenticated, isAuthenticatedWithBackend]);

  console.log(
    `Login | Rendering, path: ${path}, isLoading: ${isLoading}, isAuthenticated: ${isAuthenticated}, pollingBackend: ${pollingBackend}`
  );

  if (isAuthenticatedWithBackend) {
    console.log(`Login | Authenticated complete, redirecting to: ${path}`);
    return <>{children}</>;
  }

  if (isLoading || pollingBackend) {
    return (
      <Row justify="center">
        <LoadingOutlined spin style={{ fontSize: "100px", color: "#08c", margin: "200px auto" }} />
      </Row>
    );
  }

  // appState is set by the Auth0 callback. If it's undefined, we redirect to Auth0
  if (!appState) {
    console.log(`Login | Unauthenticated, redirecting to Auth0 with target: ${path}`);
    loginWithRedirect({ appState: { targetUrl: path } });
    return <></>;
  }

  // We get here when we've authenticated to Auth0, but we still
  // need to authenticate to the backend API using an Auth0 token.
  // This kicks off the polling loop in the useEffect above.
  setPollingBackend(true);
};
