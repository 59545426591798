import React, { FunctionComponent } from "react";
import { Button, Divider, Empty, Space, Spin, Table, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Markdown from "react-markdown";
import moment from "moment";
import Hyperlink from "../utils/Hyperlink";

// import { colorHash } from '../../utils/formatters';

import "./index.less";

const tableCols = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
      render: (d) => <Markdown >{d}</Markdown>,
  },
  {
    title: "Affected Versions",
    dataIndex: "versions",
    key: "versions",
    className: "va-top",
    width: 170,
    render: (versions) =>
      versions.length
        ? versions.map((v) => (
            <Tag key={v} color={""}>
              {v}
            </Tag>
          ))
        : "N/A",
  },
  {
    title: "Severity",
    dataIndex: "severity",
    key: "severity",
    className: "va-top",
    width: 120,
    render: (s) => {
      switch (s) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        default:
          return "N/A";
      }
    },
  },
];

interface BulletinViewProps {
  bulletins: any[];
  isLoading: boolean;
  editFn?: (id: any) => void;
  deleteFn?: (id: any) => void;
  editable?: boolean;
  type?: string;
}

const SecurityBulletinView: FunctionComponent<BulletinViewProps> = ({
  bulletins,
  isLoading = false,
  editFn = () => {},
  deleteFn = () => {},
  editable = false,
}) => (
  <>
    {isLoading && (
      <div style={{ textAlign: "center", padding: "3em", width: "100%" }}>
        <Spin size="large" />
      </div>
    )}
    {!bulletins.length && !isLoading && (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Security Bulletins" />
    )}
    {bulletins.map((b, i) => (
      <div key={b.id}>
        <h2>
          {moment(b.date_posted).format("MMMM D, YYYY")}
          {editable && (
            <div style={{ float: "right" }}>
              <Space>
                <Button
                  shape="circle"
                  size="large"
                  icon={<EditOutlined />}
                  onClick={() => {
                    editFn(b.id);
                  }}
                />
                <Button
                  shape="circle"
                  size="large"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    deleteFn(b.id);
                  }}
                />
              </Space>
            </div>
          )}
        </h2>
        <Table columns={tableCols} dataSource={[b]} pagination={false} rowKey="id" />
        {i !== bulletins.length - 1 && <Divider style={{ marginTop: ".5em" }} />}
      </div>
    ))}
  </>
);

export default SecurityBulletinView;
