import { Amplitude } from "@amplitude/react-amplitude";
import { UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Menu, Space, Typography } from "antd";
import { saveAs } from "file-saver";
import { get } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ADMIN_PERMISSION } from "../../../../shared/entitlements";
import { callAPI, useApi, useToken } from "../../api";
import "./index.less";
import "./nav.css";
const { Title } = Typography;
import { Logo } from "./Logo.png";

const UserInfo: React.FC = () => {
  const [loading, user = {}, error] = useApi("/me");
  const [gcrKey, setGcrKey] = useState("");
  const [quayKey, setQuayKey] = useState("");

  useEffect(() => {
    if (!loading && !error) {
      setGcrKey(get(user, "app_metadata.portal.gcrKey", ""));
      setQuayKey(get(user, "app_metadata.portal.quayKey", ""));
    }
  }, [loading, user, error]);

  const download = (key: string) => {
    const blob = new Blob([key], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "symbiont_docker_registry.yml");
  };

  return (
    <Amplitude>
      {({ logEvent }) => (
        <Card loading={loading}>
          <Space direction="vertical">
            <Title level={5}>{user.email}</Title>
            {/* <Text type="secondary">{user.email.split("@")[1]}</Text> */}
            {/* <Title level={5}>{user.email.split("@")[0]}</Title> */}
            {/* <Text code style={{ wordBreak: "break-all" }}>
                {user.email} */}
            {/* </Text> */}

            {gcrKey !== "" && (
              <>
                <Button
                  className="quayKeyButton"
                  onClick={() =>
                    logEvent(
                      "PORTAL_GCR_KEY_DOWNLOAD",
                      download(JSON.stringify(get(user, "app_metadata.portal.gcrKey", "")))
                    )
                  }
                >
                  Download GCR Key
                </Button>
              </>
            )}
            {quayKey !== "" && (
              <>
                <Button
                  className="quayKeyButton"
                  onClick={() =>
                    logEvent(
                      "PORTAL_QUAY_KEY_DOWNLOAD",
                      download(get(user, "app_metadata.portal.quayKey.quayKey", ""))
                    )
                  }
                >
                  Download Quay Key
                </Button>
              </>
            )}
            {quayKey === "" && gcrKey === "" && (
              <>
                <div className="quayKeyWarningBox">
                  You do not have a registry key for your user.
                  <br />
                  <br />
                  Please reach out to your Account Representative to create one.
                </div>
              </>
            )}
          </Space>
        </Card>
      )}
    </Amplitude>
  );
};

const NavHome = ({ displayLink }: { displayLink: boolean }) => {
  const icon = (
    <img
      className="logo"
      src="https://storage.googleapis.com/symbiont-branding-assets/logo_inverted.png"
    />
  )

  return displayLink ? <Link to="/">{icon}</Link> : icon;
};

export const Nav: React.FC = () => {
  const [loading, user, error] = useApi("/me");
  const { isAuthenticated, logout } = useAuth0();
  const [token] = useToken();
  const location = useLocation();

  return (
    <div className="navContent">
      <NavHome displayLink={!!user} />
      {isAuthenticated && (
        <Amplitude>
          {({ logEvent }) => (
            <Menu
              className="sym-nav nav"
              selectedKeys={[location.pathname]}
              mode="horizontal"
              disabledOverflow={true}
            >
              <Menu.Item key="/">
                <Link to="/" onClick={() => logEvent("PORTAL_NAVIGATE_HOME")}>
                  Home
                </Link>
              </Menu.Item>
              {user && user.permissions.includes(ADMIN_PERMISSION) && (
                <Menu.SubMenu title={<Link to="/admin">Admin</Link>} key="admin">
                    {/* Admin disabled pending KMS infrastructure migration */}
                    {/* <Menu.Item key="/admin">
                        <Link to="/admin">Manage Users</Link>
                        </Menu.Item> */}
                  <Menu.Item key="/admin/security-bulletins">
                    <Link to="/admin/security-bulletins">Manage Security Bulletins</Link>
                  </Menu.Item>
                </Menu.SubMenu>
              )}
              <Menu.Item key="/new_docs/">
                <Link target="_blank" to="/new_docs/" onClick={() => logEvent("PORTAL_NAVIGATE_DOCS")}>
                  Docs
                </Link>
              </Menu.Item>
              <Menu.Item key="/docs/">
                <Link target="_blank" to="/docs/" onClick={() => logEvent("PORTAL_NAVIGATE_LEGACY_DOCS")}>
                  Legacy Docs
                </Link>
              </Menu.Item>
              <Menu.Item key="/legal/" hidden={true}>
                <Link target="_blank" to="/legal/" onClick={() => logEvent("PORTAL_NAVIGATE_LEGAL_DOCS")}>
                  Legal
                </Link>
              </Menu.Item>
              <Menu.SubMenu title={<UserOutlined />} key="user">
                <UserInfo />
                <Menu.Item key="/support/security-bulletins">
                  <Link to="/support/security-bulletins" onClick={() => logEvent("PORTAL_NAVIGATE_BULLETINS")}>
                    Security Bulletins
                  </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="logout"
                  onClick={() => {
                    callAPI("post", "/logout", token, {}, true);
                    logout();
                  }}
                >
                  Logout
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          )}
        </Amplitude>
      )}
    </div>
  );
};
