import { Amplitude, AmplitudeProvider } from "@amplitude/react-amplitude";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import amplitude from "amplitude-js";
import * as React from "react";
import { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { ApplicationConfig } from "../../shared/types";
import App from "./App";
import { Login } from "./components/login";
import { AppConfigContext } from "./utils/ApplicationConfig";

Sentry.init({
  dsn: "https://124b13ffb6a54aaeb312e2395be0c02c@o370634.ingest.sentry.io/5530445",
  autoSessionTracking: true,
  integrations: [
    // @ts-ignore
    new Integrations.BrowserTracing(),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

amplitude.getInstance().init("03951347622b73048bce71b6be1ab861");

export const ApplicationConfigProvider = () => {
  const [AUTH0_CLIENT_AUDIENCE, setAUTH0_CLIENT_AUDIENCE] = useState("");
  const [AUTH0_CLIENT_DOMAIN, setAUTH0_CLIENT_DOMAIN] = useState("");
  const [AUTH0_CLIENT_ID, setAUTH0_CLIENT_ID] = useState("");
  const [AMPLITUDE_KEY, setAMPLITUDE_KEY] = useState("");
  const [appState, setAppState] = useState(null);

  const getApplicationConfig = async () => {
    const res = await fetch("/api/config", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!res.ok) {
      throw new Error(`${res.status}: ${await res.text()}`);
    }
    const config: ApplicationConfig = await res.json();
    setAUTH0_CLIENT_AUDIENCE(config.AUTH0_CLIENT_AUDIENCE);
    setAUTH0_CLIENT_DOMAIN(config.AUTH0_CLIENT_DOMAIN);
    setAUTH0_CLIENT_ID(config.AUTH0_CLIENT_ID);
    setAMPLITUDE_KEY(config.TELEMETRY_API_KEY || "03951347622b73048bce71b6be1ab861");
  };
  useEffect(() => {
    getApplicationConfig().then();
  }, []);

  const navigate = useNavigate();
  const cb = (appState) => {
    console.log(`RedirectCallback | ${JSON.stringify(appState)}`);
    setAppState(appState);
    navigate(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  };

  if (AUTH0_CLIENT_ID && AUTH0_CLIENT_DOMAIN && AUTH0_CLIENT_AUDIENCE && AMPLITUDE_KEY) {
    return (
      <AppConfigContext.Provider value={{ AUTH0_CLIENT_DOMAIN, AUTH0_CLIENT_AUDIENCE, AUTH0_CLIENT_ID }}>
        <Auth0Provider
          domain={AUTH0_CLIENT_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          audience={AUTH0_CLIENT_AUDIENCE}
          redirectUri={window.location.origin}
          onRedirectCallback={cb}
        >
          <AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={AMPLITUDE_KEY}>
            <Amplitude
              eventProperties={{
                scope: ["customer-portal"],
              }}
            >
              <Login {...{ appState }}>
                <App />
              </Login>
            </Amplitude>
          </AmplitudeProvider>
        </Auth0Provider>
      </AppConfigContext.Provider>
    );
  } else {
    return <div>Loading</div>;
  }
};

ReactDOM.render(
  <Router>
    <ApplicationConfigProvider />
  </Router>,
  document.getElementById("root")
);
