import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import * as types from "../../shared/types";
import { resolveSoa } from "dns";
import { AppUser } from "../../shared/types";
import { responsiveArray } from "antd/lib/_util/responsiveObserve";

const API_BASE_URL = "/api";

export const saveBulletin = async (bulletin: object, token: string): Promise<any> => {
  const res = await fetch(`${API_BASE_URL}/saveSecurityBulletin`, {
    method: "post",
    body: JSON.stringify(bulletin),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!res.ok) {
    throw new Error(`${res.status}: ${await res.text()}`);
  }
  return await res.json();
};

export const callAPI = async (method, path, token: string, body?: object, raw?: boolean): Promise<any> => {
  if (token !== "") {
    try {
      const res = await fetch(`${API_BASE_URL}${path}`, {
        method,
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        throw new Error(`${res.status}: ${await res.text()}`);
      }
      if (raw) {
        return await res.text();
      }
      return await res.json();
    } catch (e) {
      console.log(`callApi error ${e}`);
      throw e;
    }
  }
};

export const apiGet = async (path, token: string, raw?: boolean): Promise<any> =>
  callAPI("get", path, token, undefined, raw);

export const getUser = async (token: string) => {
  const payload = await apiGet(`/me`, token);
  return payload;
};

export const getPackage = async (release: types.SymbiontPackage, token: string) => {
  // Annoyingly, we sometimes refer to versions as  `1.2.3` and sometimes as `v1.2.3`.
  // The actual artifacts are stored in folders a la `1.2.3`, but the file names contain `v1.2.3`
  const version = release.version.slice(0, 1) === "v" ? release.version.slice(1) : release.version;
  const res = await apiGet(`/getPackage?package=${release.type}/${version}/${release.name}`, token);
  return res.signedUrl;
};

export const useToken = () => {
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  getAccessTokenSilently()
    .then((token) => {
      setToken(token);
    })
    .catch((err) => setError(err));
  return [token, error];
};

export const useApi = (path: string, raw?: boolean) => {
  const [token, tokenError] = useToken();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(1);

  useEffect(() => {
    setLoading(true);
    if (token !== "" && !tokenError) {
      apiGet(path, token, raw)
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((err) => setError(err));
    } else {
      setError(tokenError);
    }
  }, [token, tokenError, reloadTrigger]);

  return [
    loading,
    data,
    error,
    () => {
      setReloadTrigger(reloadTrigger + 1);
    },
    setData,
  ];
};

export const usePackageList: () => [boolean, types.ListPackagesPayload, any] = () => {
  const [token, tokenError] = useToken();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [error, setError] = useState(null);
  useEffect(() => {
    if (token !== "" && !tokenError) {
      const res = apiGet("/listPackages", token)
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((err) => setError(err));
    } else {
      setError(tokenError);
    }
  }, [token, tokenError]);
  return [loading, data, error];
};
