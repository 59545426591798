import { Amplitude } from "@amplitude/react-amplitude";
import { useAuth0 } from "@auth0/auth0-react";
import amplitude from "amplitude-js";
import { Layout } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { Navigate, Route, useLocation, Routes } from "react-router-dom";
/* import { Admin } from "./components/admin"; */
import { Main } from "./components/main";
import { Nav } from "./components/nav";
import { SecurityBulletin } from "./components/securityBulletin";

const { Content, Header } = Layout;

const DebugRouter = ({ children }: { children: any }) => {
  const location = useLocation();
  console.log(
    `Router | Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`
  );

  return children;
};

export const Popup = () => {
  window.open(window.location.href, "_blank") || window.location.reload();
  return <Navigate to="/" />;
};

const App = () => {
  const { user } = useAuth0();

  useEffect(() => {
    document.title = "Customer Portal";
  }, []);

  useEffect(() => {
    if (user && user.email) {
      amplitude.getInstance().setUserId(user.email);
      amplitude.getInstance().logEvent("PORTAL_USER_LOGGED_IN");
    }
  }, [user]);

  return (
    <Amplitude
      eventProperties={{
        scope: ["customer-portal-main"],
        user_id: user?.email,
      }}
    >
      <Layout>
        <Header>
          <Nav />
        </Header>
        <Content style={{ padding: "0 20px" }}>
          <DebugRouter>
            <Routes>
              <Route path="/">
                <Route path="admin">
                  <Route path="security-bulletins" element={<SecurityBulletin></SecurityBulletin>} />
                  {/* <Route index element={<Admin></Admin>} /> */}
                </Route>
                <Route path="support/security-bulletins" element={<SecurityBulletin></SecurityBulletin>} />
                <Route path="legal/*" element={<Popup />} />
                <Route path="new_docs/*" element={<Popup />} />
                <Route path="docs/*" element={<Popup />} />
                <Route path="logout" element={<Navigate to="/" />} />
                <Route index element={<Main></Main>} />
              </Route>
            </Routes>
          </DebugRouter>
        </Content>
      </Layout>
    </Amplitude>
  );
};

export default App;
