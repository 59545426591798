import React, { useEffect, useState } from "react";
import { Card, PageHeader } from "antd";
import SecurityBulletinView from "./SecurityBulletinView";
import { apiGet, useToken } from "../../api";
import amplitude from "amplitude-js";
import "./index.less";

export const SecurityBulletin = () => {
  const [bulletins, setBulletins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token] = useToken();

  useEffect(() => {
    if (token !== "") {
      apiGet("/getSecurityBulletins", token).then((bulletins) => {
        amplitude.getInstance().logEvent("PORTAL_USER_VIEWING_BULLETINS");
        console.log(bulletins);
        // Bulletins are sorted chronologically, reverse it to show the most recent up top
        setBulletins(bulletins.reverse());
        setIsLoading(false);
      });
    }
  }, [token]);

  return (
    <div
      className="security-bulletins"
      style={{
        padding: "2em",
        minWidth: "800px",
        maxWidth: "1200px",
        margin: "auto",
      }}
    >
      <Card className="sym-main-content" bordered={false} title={<PageHeader title="Security Bulletins" />}>
        <SecurityBulletinView bulletins={bulletins.filter((b) => b.type === "posted")} isLoading={isLoading} />
      </Card>
    </div>
  );
};
